/*======= file upload ========*/
.file-upload-wrap {
  position: relative;
  .file-upload-input {
    @include border-radius(4px);
    width: 100%;
    border: 2px dashed rgba(128, 137, 150, 0.2);
    height: 120px;
    text-align: center;
    cursor: pointer;
    position: relative;
    display: inline-block;
    padding: 10rem 0 0 0;
    overflow: hidden;
    z-index: 1;
    @include transition(0.3s);
    &:hover {
      border-color: rgba(128, 137, 150, 0.6);
      background-color: rgba(128, 137, 150, 0.04);
    }
  }
  .file-upload-text {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 0;
    pointer-events: none;
    line-height: 170px;
    text-align: center;
    font-size: $display-30;
    color: rgba(128, 137, 150, 0.8);
  }
  .MultiFile-list {
    > .MultiFile-label {
      @include border-radius(4px);
      background-color: rgba(128, 137, 150, 0.06);
      display: inline-block;
      border: 2px solid rgba(128, 137, 150, 0.1);
      padding: 16px;
      position: relative;
      width: 100%;
      + .MultiFile-label {
        margin-top: 20px;
      }
    }
    span.MultiFile-label {
      box-shadow: 0 0.2rem 0.7rem 0 rgba(0,0,0, .08);
      position: relative;
      text-align: center;
      display: inline-block;
      margin: 10px;
    }
    .MultiFile-remove {
      @include box-shadow(0 4px 20px rgba(0, 0, 0, 0.2));
      @include border-radius(50%);
      position: absolute;
      background-color: $white;
      color: $theme-color;
      width: 30px;
      height: 30px;
      text-align: center;
      top: -16px;
      right: -16px;
      z-index: 1;
      &:hover {
        color: $alter-color-3;
      }
    }
    .MultiFile-title {
      position: absolute;
      background-color: rgba(0,0,0, .4);
      color: $white;
      padding: 16px;
      bottom: 0;
      text-align: center;
      width: 100%;
      line-height: 24px;
      border-bottom-right-radius: 4px;
      border-bottom-left-radius: 4px;
    }
    .MultiFile-preview {
      max-width: 15rem !important;
      max-height: 15rem !important;
      @include border-radius(4px);
    }
  }
}

/*======== file-upload-wrap-2 =======*/
.file-upload-wrap-2 {
  .file-upload-input {
    width: 185px;
    border: 1px solid rgba(128, 137, 150, 0.1);
    height: 30px;
    padding-top: 3rem;
    background-color: rgba(128, 137, 150, 0.04);
  }
  .file-upload-text {
    width: auto;
    left: 25px;
    line-height: 50px;
    font-size: $default-font;
  }
}
/*======== file-upload-wrap-3 =======*/
.file-upload-wrap-3 {
  .file-upload-input {
    height: 70px;
    padding-top: 5rem;
  }
  .file-upload-text {
    line-height: 90px;
    font-size: $default-font;
  }
}