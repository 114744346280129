/*======= table-form ========*/
.table-form {
  .table {
    margin-bottom: 0;
  }
  thead {
    th {
      border: none;
      background-color: rgba(128, 137, 150, 0.1);
    }
  }
  th {
    color: $theme-color;
  }
  th,
  td {
    font-size: $default-font-2;
    vertical-align: middle;
    border-top-color: rgba(128, 137, 150, 0.1);
  }
  td {
    color: $theme-color-4;
  }
}

.table-content {
  img {
    width: 50px;
    height: 50px;
    @include border-radius(100%);
    margin-right: 10px;
  }
  .title {
    font-size: $default-font-2;
    &.table-price {
      font-size: $display-29;
    }
  }
}
/*======= product-content ========*/
.product-content {
  img {
    width: 100px;
    height: 100px;
    @include border-radius(2px);
    margin-right: 15px;
  }
}

/*======= table-layout-2 ========*/
.table-layout-2 {
  th,
  td {
    border: none;
  }
  tbody {
    tr {
      &:nth-child(even) {
        background-color: rgba(128, 137, 150, 0.03);
      }
    }
  }
}