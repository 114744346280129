.section-padding {
  padding-top: 100px;
  padding-bottom: 100px;
}
.section--padding {
  padding-top: 100px;
  padding-bottom: 90px;
}

/*===== padding-top ========*/
.padding-top-200px {
  padding-top: 200px;
}
.padding-top-190px {
  padding-top: 190px;
}
.padding-top-180px {
  padding-top: 180px;
}
.padding-top-170px {
  padding-top: 170px;
}
.padding-top-160px {
  padding-top: 160px;
}
.padding-top-150px {
  padding-top: 150px;
}
.padding-top-140px {
  padding-top: 140px;
}
.padding-top-130px {
  padding-top: 130px;
}
.padding-top-120px {
  padding-top: 120px;
}
.padding-top-110px {
  padding-top: 110px;
}
.padding-top-100px {
  padding-top: 100px;
}
.padding-top-95px {
  padding-top: 95px;
}
.padding-top-90px {
  padding-top: 90px;
}
.padding-top-85px {
  padding-top: 85px;
}
.padding-top-80px {
  padding-top: 80px;
}
.padding-top-70px {
  padding-top: 70px;
}
.padding-top-60px {
  padding-top: 60px;
}
.padding-top-50px {
  padding-top: 50px;
}
.padding-top-50px {
  padding-top: 50px;
}
.padding-top-45px {
  padding-top: 45px;
}
.padding-top-40px {
  padding-top: 40px;
}
.padding-top-35px {
  padding-top: 35px;
}
.padding-top-38px {
  padding-top: 38px;
}
.padding-top-30px {
  padding-top: 30px;
}
.padding-top-20px {
  padding-top: 20px;
}
.padding-top-10px {
  padding-top: 10px;
}

/*===== padding-bottom ========*/
.padding-bottom-200px {
  padding-bottom: 200px;
}
.padding-bottom-190px {
  padding-bottom: 190px;
}
.padding-bottom-180px {
  padding-bottom: 180px;
}
.padding-bottom-170px {
  padding-bottom: 170px;
}
.padding-bottom-160px {
  padding-bottom: 160px;
}
.padding-bottom-150px {
   padding-bottom: 150px;
}
.padding-bottom-140px {
    padding-bottom: 140px;
 }
.padding-bottom-130px {
  padding-bottom: 130px;
}
.padding-bottom-120px {
  padding-bottom: 120px;
}
.padding-bottom-110px {
  padding-bottom: 110px;
}
.padding-bottom-100px {
  padding-bottom: 100px;
}
.padding-bottom-95px {
  padding-bottom: 95px;
}
.padding-bottom-90px {
  padding-bottom: 90px;
}
.padding-bottom-85px {
  padding-bottom: 85px;
}
.padding-bottom-80px {
  padding-bottom: 80px;
}
.padding-bottom-70px {
  padding-bottom: 70px;
}
.padding-bottom-60px {
  padding-bottom: 60px;
}
.padding-bottom-50px {
  padding-bottom: 50px;
}
.padding-bottom-45px {
  padding-bottom: 45px;
}
.padding-bottom-40px {
  padding-bottom: 40px;
}
.padding-bottom-35px {
  padding-bottom: 35px;
}
.padding-bottom-30px {
  padding-bottom: 30px;
}
.padding-bottom-20px {
  padding-bottom: 20px;
}
.padding-bottom-10px {
  padding-bottom: 10px;
}

/*===== padding-right ========*/
.padding-right-150px {
  padding-right: 150px;
}
.padding-right-140px {
  padding-right: 140px;
}
.padding-right-130px {
  padding-right: 130px;
}
.padding-right-120px {
  padding-right: 120px;
}
.padding-right-110px {
  padding-right: 110px;
}
.padding-right-100px {
  padding-right: 100px;
}
.padding-right-95px {
  padding-right: 95px;
}
.padding-right-90px {
  padding-right: 90px;
}
.padding-right-85px {
  padding-right: 85px;
}
.padding-right-80px {
  padding-right: 80px;
}
.padding-right-70px {
  padding-right: 70px;
}
.padding-right-60px {
  padding-right: 60px;
}
.padding-right-55px {
  padding-right: 55px;
}
.padding-right-50px {
  padding-right: 50px;
}
.padding-right-45px {
  padding-right: 45px;
}
.padding-right-40px {
  padding-right: 40px;
}
.padding-right-30px {
  padding-right: 30px;
}

/*===== padding-left ========*/
.padding-left-150px {
  padding-left: 150px;
}
.padding-left-140px {
  padding-left: 140px;
}
.padding-left-130px {
  padding-left: 130px;
}
.padding-left-120px {
  padding-left: 120px;
}
.padding-left-110px {
  padding-left: 110px;
}
.padding-left-100px {
  padding-left: 100px;
}
.padding-left-95px {
  padding-left: 95px;
}
.padding-left-90px {
  padding-left: 90px;
}
.padding-left-85px {
  padding-left: 85px;
}
.padding-left-80px {
  padding-left: 80px;
}
.padding-left-70px {
  padding-left: 70px;
}
.padding-left-60px {
  padding-left: 60px;
}
.padding-left-55px {
  padding-left: 55px;
}
.padding-left-50px {
  padding-left: 50px;
}
.padding-left-45px {
  padding-left: 45px;
}
.padding-left-40px {
  padding-left: 40px;
}
.padding-left-30px {
  padding-left: 30px;
}

/*===== margin ====*/
.margin-top-200px {
   margin-top: 200px;
 }
.margin-top-190px {
   margin-top: 190px;
 }
.margin-top-180px {
   margin-top: 180px;
 }
.margin-top-160px {
   margin-top: 160px;
 }
.margin-top-150px {
   margin-top: 150px;
 }
.margin-top-140px {
   margin-top: 140px;
 }
.margin-top-130px {
   margin-top: 130px;
 }
.margin-top-120px {
   margin-top: 120px;
 }
.margin-top-110px {
  margin-top: 110px;
}
.margin-top-100px {
  margin-top: 100px;
}
.margin-top-95px {
  margin-top: 95px;
}
.margin-top-90px {
  margin-top: 90px;
}
.margin-top-85px {
  margin-top: 85px;
}
.margin-top-80px {
  margin-top: 80px;
}
.margin-top-70px {
  margin-top: 70px;
}
.margin-top-60px {
  margin-top: 60px;
}
.margin-top-55px {
  margin-top: 55px;
}
.margin-top-50px {
  margin-top: 50px;
}
.margin-top-45px {
  margin-top: 45px;
}
.margin-top-40px {
  margin-top: 40px;
}
.margin-top-35px {
  margin-top: 35px;
}
.margin-top-30px {
  margin-top: 30px;
}
.margin-top-20px {
  margin-top: 20px;
}
.margin-top-10px {
  margin-top: 10px;
}

.margin-bottom-200px {
  margin-bottom: 200px;
}
.margin-bottom-190px {
  margin-bottom: 190px;
}
.margin-bottom-180px {
  margin-bottom: 180px;
}
.margin-bottom-170px {
  margin-bottom: 170px;
}
.margin-bottom-160px {
  margin-bottom: 160px;
}
.margin-bottom-150px {
  margin-bottom: 150px;
}
.margin-bottom-140px {
  margin-bottom: 140px;
}
.margin-bottom-130px {
  margin-bottom: 130px;
}
.margin-bottom-120px {
  margin-bottom: 120px;
}
.margin-bottom-110px {
  margin-bottom: 110px;
}
.margin-bottom-100px {
  margin-bottom: 100px;
}
.margin-bottom-95px {
  margin-bottom: 95px;
}
.margin-bottom-90px {
  margin-bottom: 90px;
}
.margin-bottom-85px {
  margin-bottom: 85px;
}
.margin-bottom-80px {
  margin-bottom: 80px;
}
.margin-bottom-70px {
  margin-bottom: 70px;
}
.margin-bottom-60px {
  margin-bottom: 60px;
}
.margin-bottom-50px {
  margin-bottom: 50px;
}
.margin-bottom-55px {
  margin-bottom: 55px;
}
.margin-bottom-45px {
  margin-bottom: 45px;
}
.margin-bottom-40px {
  margin-bottom: 40px;
}
.margin-bottom-35px {
  margin-bottom: 35px;
}
.margin-bottom-30px {
  margin-bottom: 30px;
}
.margin-bottom-20px {
  margin-bottom: 20px;
}
.margin-bottom-10px {
  margin-bottom: 10px;
}

/*========= preloader ==========*/
.preloader {
  position: fixed;
  top: 0;
  width: 100%;
  height: 100%;
  z-index: 9999;
  background-color: $white;
}
.loader {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  .spinner {
    animation: rotate 2s linear infinite;
    z-index: 2;
    width: 50px;
    height: 50px;
    & .path {
      stroke: $theme-color-2;
      stroke-linecap: round;
      animation: dash 1.5s ease-in-out infinite;
    }
  }
}

.before-price {
  text-decoration: line-through;
  color: $theme-color-4 !important;
}
/*====================================================
    cd-words-wrapper
 ====================================================*/
.cd-words-wrapper {
  b {
    font-weight: $font-weight-bold;
  }
}
/*-===============================
    line-bg
===============================-*/
.line-bg {
  position: absolute;
  width: 1px;
  height: 100%;
  background-color: rgba(255, 255, 255, 0.1);
  top: 0;
  &:before {
    position: absolute;
    bottom: -60%;
    left: 0;
    content: "";
    height: 80px;
    width: 100%;
    background-color: rgba(255, 255, 255, 0.4);
  }
}
.line-bg1 {
  left: 14%;
}
.line-bg2 {
  left: 30%;
}
.line-bg3 {
  left: 45%;
}
.line-bg4 {
  left: 60%;
}
.line-bg5 {
  left: 75%;
}
.line-bg6 {
  left: 88%;
}
/*====================================================
    section-heading
 ====================================================*/
.section-heading {
  .sec__title {
    font-size: $primary-font;
    color: $theme-color;
    font-weight: $font-weight-bold;
    @media #{$small_mobile_four} {
      font-size: $display-18;
      line-height: 42px !important;
    }
    br {
       @media #{$tab_device} {
        display: none;
      }
      @media #{$large_mobile} {
        display: none;
      }
       @media #{$small_mobile} {
        display: none;
      }
    }
  }
  .sec__desc {
    font-size: $display-30;
    color: $theme-color-4;
    line-height: 30px;
    br {
      @media #{$tab_device} {
        display: none;
      }
      @media #{$large_mobile} {
        display: none;
      }
      @media #{$small_mobile} {
        display: none;
      }
    }
  }
}

/*==== title =====*/
.title {
  font-size: $display-30;
  color: $theme-color;
}
/*==== curve-shape =====*/
.curve-shape {
  position: relative;
  display: inline-block;
  &::before {
    position: absolute;
    left: 0;
    bottom: 0;
    content: attr(data-text);
    color: transparent;
    text-decoration-style: wavy;
    text-decoration-color: $theme-color-2;
    text-decoration-line: underline;
  }
}
/*==== stroke-shape =====*/
.stroke-shape {
  position: relative;
  display: inline-block;
  &::before,
  &::after{
    position: absolute;
    content: "";
    bottom: 0;
    left: 0;
    height: 2px;
    width: 45px;
    background-color: $theme-color-2;
  }
  &::after {
    width: 7px;
    background-color: $white;
    left: 25px;
  }
}
/*====================================================
    custom-checkbox
 ====================================================*/
.custom-checkbox {
  display: block;
  margin-bottom: 8px;
  label {
    color: $theme-color-4;
    font-size: $default-font-2;
    display: block;
    margin-bottom: 0;
    cursor: pointer;
    a {
      color: $theme-color-2;
      @include transition(0.3s);
      &:hover {
        color: $theme-color;
      }
    }
  }
  input[type=checkbox]:not(:checked),
  input[type=checkbox]:checked {
    position: absolute;
    left: -9999px;
  }
  input[type=checkbox]:not(:checked) + label,
  input[type=checkbox]:checked + label {
    position: relative;
    padding-left: 26px;
  }
  input[type=checkbox]:not(:checked) + label:before,
  input[type=checkbox]:checked + label:before {
    content: "";
    position: absolute;
    left: 0;
    top: 5px;
    width: 18px;
    height: 18px;
    border: 1px solid $color-2;
    background-color: $white;
    @include border-radius(2px);
  }
  input[type=checkbox]:not(:checked) + label:after,
  input[type=checkbox]:checked + label:after {
    content: '\f00c';
    position: absolute;
    top: 1px;
    left: 3px;
    font-size: $default-font-5;
    color: $white;
    @include transition(0.3s);
    font-family: "Line Awesome Free";
    font-weight: 900;
  }
  input[type=checkbox]:not(:checked) + label:after {
    opacity: 0;
    visibility: hidden;
  }
  input[type=checkbox]:checked + label:after {
    opacity: 1;
    visibility: visible;
  }
  input[type=checkbox]:checked + label:before {
    background-color: $theme-color-2;
    border-color: $theme-color-2;
  }
}

/*======= social-profile ========*/
.social-profile {
  li {
    display: inline-block;
    margin-right: 3px;
    &:last-child {
      margin-right: 0;
    }
    a {
      display: block;
      color: $theme-color;
      width: 38px;
      height: 38px;
      line-height: 38px;
      text-align: center;
      background-color: rgba(128, 137, 150, 0.1);
      @include transition(0.3s);
      @include border-radius(50%);
      &:hover {
        background-color: $theme-color-2;
        color: $white;
      }
    }
  }
}

/*======= social--profile ========*/
.social--profile {
  li {
    a {
      background-color: rgba(255, 255, 255, 0.1);
      color: $white;
      &:hover {
        background-color: $theme-color-2;
        color: $white;
      }
    }
  }
}

/*======= info-list ========*/
.info-list {
  li {
    display: block;
    color: $theme-color-4;
    margin-bottom: 6px;
    &:last-child {
      margin-bottom: 0;
    }
    a {
      color: $theme-color-4;
    }
    .icon-element {
      color: $theme-color-2;
      font-size: $default-font;
      width: 30px;
      height: 30px;
      background-color: rgba(40, 125, 250, 0.1);
      line-height: 30px;
    }
    &:hover {
      a {
        color: $theme-color-2;
      }
      .icon-element {
        background-color: $theme-color-2;
        color: $white;
      }
    }
  }
}
/*======= list-items ========*/
.list-items {
  li {
    margin-bottom: 6px;
    color: $theme-color-4;
    a {
      color: $theme-color-4;
      @include transition(0.3s);
      &:hover {
        color: $theme-color-2;
      }
    }
  }
}

/*======= list--items ========*/
.list--items {
  a {
    position: relative;
    display: inline-block;
    &::before {
      position: absolute;
      content: "";
      bottom: 1px;
      left: 0;
      width: 100%;
      height: 2px;
      background-color: $theme-color-2;
      @include transform(scale(0));
      @include transition(0.3s);
    }
    &:hover {
      &::before {
        @include transform(scale(1));
      }
    }
  }
}
/*======= list-items-2 ========*/
.list-items-2 {
  li {
    span {
      display: inline-block;
      width: 150px;
      color: $theme-color;
      font-weight: $font-weight-medium;
    }
  }
}
/*======= list-items-2 ========*/
.list--items-2 {
  > li {
    border-bottom: 1px solid rgba(128, 137, 150, 0.1);
    margin-bottom: 10px;
    padding-bottom: 10px;
    span {
      width: 210px;
      @media #{$small_mobile_five} {
        width: 100%;
      }
    }
  }
}
/*======= list-items-3 ========*/
.list-items-3 {
  li {
    display: -webkit-flex;
    display: -ms-flex;
    display: flex;
    -ms-flex-align: center;
    align-items: center;
    -ms-flex-pack: justify;
    justify-content: space-between;
    @media #{$small_mobile_four} {
      display: block;
    }
    &:last-child {
      border-bottom: 0;
      padding-bottom: 0;
      margin-bottom: 0;
    }
    span {
      width: auto;
      margin-right: 20px;
      @media #{$small_mobile_four} {
        display: block;
        margin-right: 0;
      }
    }
  }
}
/*===== ripple-bg =======*/
.ripple-bg {
  canvas {
    width: 100%;
    height: 100%;
  }
}

/*===== toggle-drop-menu =======*/
.toggle-drop-menu {
  display: none;
}

/*======== image-box =========*/
.image-box {
  position: relative;
  .img__item {
    width: 100%;
    @include border-radius(5px);
  }
  .img__item-2 {
    position: absolute;
    bottom: -50px;
    left: -80px;
    @include border-radius(100%);
    width: 190px;
    height: 190px;
    border: 8px solid $white;
    @media #{$tab_device_two} {
      left: -10px;
    }
  }
}

/*======== photo-block-item =========*/
.photo-block-item {
  margin-bottom: 30px;
  img {
    width: 100%;
    @include border-radius(5px);
  }
}

/*======== toggle-menu =========*/
.toggle-menu {
  li {
    .toggle-menu-icon {
     .la {
       @include transition(0.3s);
     }
    }
    &.active {
      .toggle-menu-icon {
       .la {
         @include transform(rotate(-180deg));
       }
      }
    }
  }
}

/*=============== tip ===============*/
.tip {
  display: inline-block;
  cursor: help;
  @include border-radius(50%);
  font-size: $display-30;
  line-height: 18px;
  width: 18px;
  height: 18px;
  position: relative;
  top: 2px;
  left: 1px;
}
.tip-verified {
  background-color: $theme-color-5;
}
/*================= pagination ==================*/
.page-item {
  .page-link-nav {
    &:first-child,
    &:last-child {
      @include border-radius(50%);
    }
  }
}
.page-link-nav {
  color: $theme-color-2;
  margin-right: 5px;
  @include border-radius(50%);
  border: none;
  @include transition(0.3s);
  background-color: rgba(40, 125, 250, 0.07);
  width: 34px;
  line-height: 34px;
  padding: 0;
  text-align: center;
  height: 34px;
  &:hover {
    color: $white;
    background-color: $theme-color-2;
  }
}

/*======== filter-top =========*/
.filter-top {
  @media #{$small_mobile_five} {
    display: block !important;
  }
}
/*======== layout-view =========*/
.layout-view {
  @include border-radius(5px);
  border: 1px solid rgba(128,137,150,0.2);
  padding: 4px 10px 4px 10px;
  width: 70px;
  @media #{$small_mobile_five} {
    margin-top: 15px;
  }
  a{
    color: $theme-color-4;
    margin-right: 3px;
    font-size: $display-26;
    @include transition(0.3s);
    &:nth-child(2) {
      margin-right: 0;
    }
    &.active {
      color: $theme-color;
    }
    &:hover {
      color: $theme-color-2;
    }
  }
}

/*======== filter-bar =========*/
.filter-bar {
  @include border-radius(5px);
  background-color: $white;
  @include box-shadow(0 0 40px rgba(82, 85, 90, 0.1));
  padding: 20px;
  border: 1px solid rgba(128,137,150,0.1);
  @media #{$large_mobile} {
    display: block !important;
  }
  @media #{$small_mobile} {
    display: block !important;
  }
}

.filter-option {
  margin-right: 20px;
  @media #{$extra-device} {
    margin-right: 10px;
  }
  @media #{$small_mobile_five} {
    margin-right: 0;
    margin-bottom: 10px;
  }
  .dropdown-contain {
    .dropdown-menu {
      width: 245px;
      margin-top: 15px;
      @media #{$extra-device} {
        left: -90px !important;
      }
      @media #{$small_mobile_four} {
        left: -20px !important;
      }
      @media #{$small_mobile_five} {
        left: 0 !important;
      }
    }
  }
  .price-slider-amount {
    .amounts {
      background-color: transparent;
      text-align: left;
      @include border-radius(0);
    }
  }
}

/*-========= filter-bar-filter =========-*/
.filter-bar-filter {
  @media #{$large_mobile} {
    padding-bottom: 20px;
  }
  @media #{$small_mobile_five} {
    display: block !important;
    padding-bottom: 10px;
  }
}
.bootstrap-select .dropdown-toggle .filter-option {
  @media #{$small_mobile_five} {
    margin-bottom: 0;
  }
}
/*-========= select-contain =========-*/
.select-contain {
  position: relative;
  width: 190px;
  .bootstrap-select {
    width: 100% !important;
    .flag-icon {
      width: 16px;
      line-height: inherit;
    }
  }
  .dropdown-toggle {
    padding: 14px 20px;
    border-color: rgba(128, 137, 150, 0.2) !important;
    background-color: $white !important;
    color: $theme-color !important;
    font-size: $default-font-3;
    position: relative;
    &::after {
      border: none;
      content: "\f107";
      font-family: "Line Awesome Free";
      font-weight: 900;
      font-size: $default-font-4;
      position: absolute;
      top: 14px;
      right: 20px;
    }
    &:focus {
      outline: none !important;
      border-color: $theme-color-2 !important;
      @include box-shadow(none !important);
    }
  }
  .dropdown-menu {
    border: 1px solid rgba(128, 137, 150, 0.2);
    .bs-searchbox {
      display: none;
    }
    .inner {
      &::-webkit-scrollbar {
        width: 8px;
      }
      &::-webkit-scrollbar-track {
        background-color: rgba(128, 137, 150, 0.1);
        @include border-radius(10px);
      }
      &::-webkit-scrollbar-thumb {
        background-color: rgba(128, 137, 150, 0.2);
        @include border-radius(10px);
      }
      &::-webkit-scrollbar-thumb:hover {
        background-color: rgba(128, 137, 150, 0.6);
      }
    }
  }
  .dropdown-item {
    color: $theme-color-4;
    @include transition(0.3s);
    padding-right: 15px;
    padding-left: 15px;
    font-size: $default-font-2;
    &.active {
      background-color: $theme-color-2;
      color: $white;
      &:hover {
        background-color: $theme-color-2;
        color: $white;
      }
    }
    &:focus {
      outline: none;
    }
    &:hover {
      background-color: rgba(40, 125, 250, 0.1);
      color: $theme-color-2;
    }
  }
}
/* select--contain */
.select--contain {
  .dropdown-toggle{
    &::after {
      top: 3px;
      right: 3px;
      font-size: $default-font-5;
    }
  }
}
/* select-wrap-form */
.select-wrap-form {
  position: relative;
  .form-control {
    -webkit-appearance: none;
    -moz-appearance: none;
    color: $theme-color;
    cursor: pointer;
    &:focus {
      border-color: $theme-color-2;
      @include box-shadow(0 0 0 0);
    }
  }
  &::after {
    position: absolute;
    right: 10px;
    top: 5px;
    border: none;
    content: "\f107";
    font-family: 'Line Awesome Free';
    font-size: $default-font-3;
    font-weight: 900;
    color: $theme-color-4;
  }
}
/*======= qty =======*/
.qty-box {
  label {
    color: $theme-color;
    font-size: $default-font-2;
    margin-bottom: 0;
    span {
      display: block;
      font-size: $default-font-4;
      margin-top: -10px;
      color: $theme-color-4;
    }
  }
}
.roomInc,
.roomDec,
.qtyInc,
.qtyDec {
  width: 28px;
  height: 28px;
  line-height: 29px;
  font-size: $display-30;
  background-color: rgba(128, 137, 150, 0.08);
  color: $theme-color;
  display: inline-block;
  text-align: center;
  @include border-radius(100%);
  cursor: pointer;
  position: relative;
  @include transition(0.3s);
  &:hover {
    background-color: $theme-color-2;
    color: $white;
  }
}

.roomBtn,
.qtyBtn {
  input {
    width: 35px;
    border: none;
    text-align: center;
    color: $theme-color;
    font-weight: $font-weight-medium;
  }
}

/*======= ui-widget-content =======*/
.ui-widget.ui-widget-content {
  border: none;
  background-color: rgba(128, 137, 150, 0.2);
  @include border-radius(30px);
  height: 6px;
  .ui-slider-range {
    background-color: $theme-color-2;
  }
  .ui-slider-handle {
    background-color: $theme-color-2;
    @include box-shadow(0 0 20px rgba(0, 0, 0, 0.2));
    border: 5px solid $white;
    @include border-radius(30px);
    height: 18px;
    width: 18px;
    top: -6px;
    cursor: ew-resize;
    outline: 0;
  }
}
/*======= price-slider-amount =======*/
.price-slider-amount {
  .filter__label {
    color: $theme-color;
    margin-bottom: 0;
    font-size: $default-font;
    font-weight: $font-weight-medium;
    margin-right: 5px;
  }
  .amounts {
    border: none;
    color: $theme-color-2;
    font-weight: $font-weight-bold;
    background-color: rgba(40, 125, 250, 0.06);
    @include border-radius(5px);
    width: 110px;
    text-align: center;
    padding-top: 2px;
    padding-bottom: 2px;
  }
}

/*======== back-to-top =========*/
#back-to-top {
  position: fixed;
  right: -150px;
  bottom: 40px;
  z-index: 9999;
  width: 40px;
  height: 40px;
  text-align: center;
  line-height: 40px;
  @include border-radius(50px);
  background-color: $white;
  @include box-shadow(0 10px 40px rgba(82, 85, 90, 0.2));
  color: $theme-color;
  font-size: $display-30;
  @include transition(0.3s);
  cursor: pointer;
  opacity: 0;
  visibility: hidden;
  &:hover {
    background-color: $theme-color-3;
    color: $white;
    border-color: $theme-color-3;
  }
  &.active {
    right: 30px;
    opacity: 1;
    visibility: visible;
  }
}

/*====================================================
    date range picker
 ====================================================*/
.daterangepicker {
  font-family: $theme-font;
  border-color: rgba(128, 137, 150, 0.3);
  .calendar-table {
    th,
    td {
      color: $theme-color;
    }
  }
  td {
    &.off {
      color: $theme-color-4;
    }
    &.active {
      background-color: $theme-color-2;
      color: $white;
    }
  }
}

/*==== ratings =======*/
.ratings {
  i {
    color: $color-5;
  }
}

/*==== rate-option-item =======*/
.rate-option-item {
  label {
    margin-bottom: 0;
  }
}
.rate-stars-option {
  text-align: left;
  position: relative;
  display: table;
  margin-bottom: 15px;
  input {
    border: 0;
    width: 0;
    height: 0;
    overflow: hidden;
    position: absolute;
    opacity: 0;
  }
  input:checked ~ label:before {
    color: $color-5;
    content: "\f005";
  }
  label {
    position: relative;
    float:right;
    cursor: pointer;
    margin-bottom: 0;
    color: rgba(128, 137, 150, 0.6);
    font-size: $display-30;
    &::before {
      margin-right: 9px;
      content: "\f006";
      font-family: "FontAwesome";
      display: inline-block;
      -webkit-user-select: none;
      -moz-user-select: none;
      user-select: none;
    }
    &:hover ~ label:before {
      color: $color-5;
    }
    &:hover:before {
      color: $color-5;
    }
  }
}

/*====================================================
    radio-option
 ====================================================*/
.radio-trigger {
  position: relative;
  padding-left: 25px;
  margin-bottom: 15px;
  cursor: pointer;
  font-size: $default-font;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  color: $theme-color;
  input[type=radio] {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0;
    &:checked ~ .checkmark {
      border-color: $theme-color-2;
      &:before {
        border-color: $theme-color-2;
      }
      &:after {
        @include transform(scale(1));
        opacity: 1;
        visibility: visible;
      }
    }
  }
  .checkmark {
    position: absolute;
    top: 4px;
    left: 0;
    width: 19px;
    height: 19px;
    border: 2px solid $color-2;
    @include border-radius(50%);
    @include transition(0.3s);
    &:after {
      position: absolute;
      left: 3px;
      top: 3px;
      content: '';
      width: 9px;
      height: 9px;
      background-color: $theme-color-2;
      opacity: 0;
      visibility: hidden;
      @include transform(scale(0.1));
      @include border-radius(50%);
      @include transition(0.3s);
    }
  }
}
.ck-editor__main {
  ul {
    list-style: disc;
  }
}
.ck.ck-toolbar {
  border-color: rgba(128, 137, 150, 0.2) !important;
}
.ck.ck-editor__editable_inline {
  padding-right: 25px !important;
  padding-left: 25px !important;
  border-color: rgba(128, 137, 150, 0.2) !important;
  &:focus {
    @include box-shadow(none !important);
    outline: none !important;
  }
}